import { BASE_URL } from 'src/utils/constants'
import axios from 'axios'
import { Key } from 'src/helper/KeyStorage'
import { ResponseError } from 'src/helper/ResponseError'
import { Auth } from 'src/helper/Auth'
import { store } from 'src/redux/store/store'
import { logoutAction, logoutActionNotAllow } from '../../redux/actions/loginAction'

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-type': 'application/json',
  },
})

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(Key.token)
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response && error?.response?.data) {
      const code = error?.response?.data?.error?.code
      const statusCode = error?.response?.status
      if (
        code === ResponseError.NO_AUTHENTICATED_ERROR.code ||
        code === ResponseError.NOT_ALLOW.code
      ) {
        Auth.removeToken()
        store.dispatch(logoutAction())
        // window.location.href = '/'
        return null
      } else if (statusCode === ResponseError.SYSTEM_ERROR_503.code) {
        window.location.href = '/maintenance'
        return null
      } else if (
        statusCode >= ResponseError.SYSTEM_ERROR_500.code &&
        statusCode <= ResponseError.SYSTEM_ERROR_510.code
      ) {
        window.dispatchEvent(new CustomEvent('server_error', { detail: true }))
        return null
      }
    }
    return Promise.reject(error)
  },
)

export default instance
