export const ResponseError = {
  // -- SYSTEM errors
  SYSTEM_ERROR: { message: 'SYSTEM_ERROR' },
  ENV_NOT_SET_ERROR: { message: 'ENV_NOT_SET_ERROR' },
  SERVER_SHUTTING_DOWN: { message: 'SERVER_SHUTTING_DOWN' },
  LISTEN_ERROR: { message: 'LISTEN_ERROR' },

  // -- API COMMON errors
  SYSTEM_ERROR_500: { code: 500, message: 'SYSTEM_ERROR_500' },
  SYSTEM_ERROR_503: { code: 503, message: 'SYSTEM_ERROR_503' },
  SYSTEM_ERROR_510: { code: 510, message: 'SYSTEM_ERROR_510' },
  INTERNAL_SERVER_ERROR: { code: 8003, message: 'INTERNAL_SERVER_ERROR' },
  NOT_FOUND_DATA_ERROR: { code: 8001, message: 'NOT_FOUND_DATA_ERROR' },
  NOT_ALLOW_PERMISSION: { code: 8004, message: 'NOT_ALLOW_PERMISSION' },
  NOT_FOUND_API_URL_ERROR: { code: 8005, message: 'NOT_FOUND_API_URL_ERROR' },
  NO_AUTHENTICATED_ERROR: { code: 5100, message: 'NO_AUTHENTICATED_ERROR' },
  NOT_ALLOW: { code: 403, message: 'NOT_ALLOW' },
  INVALID_PAGE: { code: 8007, message: 'INVALID_PAGE' },
  INVALID_EMAIL: { code: 8002, message: 'INVALID_EMAIL' },
  ACCOUNT_DELETED: { code: 40081 },
  LOGIN_ERROR: { code: 202, message: 'LOGIN_ERROR' },
  INVALID_TOKEN: { code: 40043, message: 'INVALID_EMAIL' },
  TOKEN_EXPIRED_ERROR: { code: 40044, message: 'TOKEN_EXPIRED_ERROR' },
  NO_DATA_FOUND: { code: 40401, message: 'NO_DATA_FOUND' },
  REGISTER_ERROR: { code: 40066, message: 'REGISTER_ERROR' },
}
