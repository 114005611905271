const ACTION_GET_MONTH_CONTRACT = 'ACTION_GET_MONTH_CONTRACT'
const ACTION_GET_MONTH_CONTRACT_SUCCESS = 'ACTION_GET_MONTH_CONTRACT_SUCCESS'
const ACTION_GET_MONTH_CONTRACT_RESET = 'ACTION_GET_MONTH_CONTRACT_RESET'
const ACTION_GET_MONTH_CONTRACT_DETAIL = 'ACTION_GET_MONTH_CONTRACT_DETAIL'
const ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS = 'ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS'
const ACTION_GET_MONTH_CONTRACT_DETAIL_RESET = 'ACTION_GET_MONTH_CONTRACT_DETAIL_RESET'
const ACTION_GET_METER_DETAIL = 'ACTION_GET_METER_DETAIL'
const ACTION_GET_METER_DETAIL_SUCCESS = 'ACTION_GET_METER_DETAIL_SUCCESS'
const ACTION_GET_METER_SUMMARY_RESET = 'ACTION_GET_METER_SUMMARY_RESET'
const ACTION_GET_MONTH_BILL_DETAIL = 'ACTION_GET_MONTH_BILL_DETAIL'
const ACTION_GET_MONTH_BILL_DETAIL_SUCCESS = 'ACTION_GET_MONTH_BILL_DETAIL_SUCCESS'
const ACTION_GET_PDF_BILL = 'ACTION_GET_PDF_BILL'
const ACTION_GET_PDF_BILL_SUCCESS = 'ACTION_GET_PDF_BILL_SUCCESS'

export {
  ACTION_GET_MONTH_CONTRACT,
  ACTION_GET_MONTH_CONTRACT_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_RESET,
  ACTION_GET_MONTH_CONTRACT_DETAIL,
  ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_DETAIL_RESET,
  ACTION_GET_METER_DETAIL,
  ACTION_GET_METER_DETAIL_SUCCESS,
  ACTION_GET_METER_SUMMARY_RESET,
  ACTION_GET_MONTH_BILL_DETAIL,
  ACTION_GET_MONTH_BILL_DETAIL_SUCCESS,
  ACTION_GET_PDF_BILL,
  ACTION_GET_PDF_BILL_SUCCESS,
}
