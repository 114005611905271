import React from 'react'
import i18n from 'src/configs/i18n'

const Login = React.lazy(() => import('../views/pages/login/Login'))
const Register = React.lazy(() => import('../views/pages/register/Register'))
const SecuritySafety = React.lazy(() => import('../views/pages/my-page/SecuritySafety'))
const SendMail = React.lazy(() => import('../views/pages/my-page/SendMail'))
const ForgotPassword = React.lazy(() => import('../views/pages/password/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../views/pages/password/ResetPassword'))
const Top = React.lazy(() => import('../views/pages/top/TopPage'))
const Statement = React.lazy(() => import('../views/pages/top/Statement'))
const GasMeter = React.lazy(() => import('../views/pages/top/meter-reading-slip-detail/GasMeter'))
const ElectricMeter = React.lazy(() =>
  import('../views/pages/top/meter-reading-slip-detail/ElectricMeter'),
)
const EnergySaving = React.lazy(() => import('../views/pages/energy-saving/EnergySavingIndex'))
const EnergySavingContent = React.lazy(() =>
  import('../views/pages/energy-saving/energy-saving-content/EnergySavingContent'),
)
const Notification = React.lazy(() => import('../views/pages/notification/NotificationIndex'))
const NotificationDetail = React.lazy(() =>
  import('../views/pages/notification/notificationDetail/NotificationDetail'),
)
const MyPage = React.lazy(() => import('../views/pages/my-page/MyPage'))
const FAQ = React.lazy(() => import('../views/pages/guide-pages/faq/FAQ'))
const UsageGuide = React.lazy(() => import('../views/pages/guide-pages/usage-guide/UsageGuide'))
const FirstTimeRegisterGuide = React.lazy(() =>
  import('../views/pages/guide-pages/first-time-register-guide/FirstTimeRegisterGuide'),
)
const LoginInstructions = React.lazy(() =>
  import('../views/pages/guide-pages/login-instructions/LoginInstructions'),
)
const AboutApp = React.lazy(() =>
  import('../views/pages/guide-pages/about-mitsubachi-app/AboutApp'),
)
const TermsOfService = React.lazy(() =>
  import('../views/pages/guide-pages/terms-of-service/TermsOfService'),
)
const PrivacyPolicy = React.lazy(() =>
  import('../views/pages/guide-pages/privacy-policy/PrivacyPolicy'),
)
const ContactList = React.lazy(() => import('../views/pages/guide-pages/contact-list/ContactList'))
const FormQA = React.lazy(() => import('../views/pages/guide-pages/form-qa/FormQA'))
const ProfileSettings = React.lazy(() => import('../views/pages/my-page/ProfileSettings'))
const InquiryForm = React.lazy(() => import('../views/pages/my-page/InquiryForm'))
const ResetId = React.lazy(() => import('../views/pages/my-page/ResetId'))
const ChangePassword = React.lazy(() => import('../views/pages/my-page/ChangePassword'))
const ContractInfo = React.lazy(() => import('../views/pages/my-page/ContractInfo'))
const NotificationSetting = React.lazy(() => import('../views/pages/my-page/NotificationSetting'))
const UrlExpired = React.lazy(() => import('../views/pages/error/UrlExpired'))
const PageNotExist = React.lazy(() => import('../views/pages/error/PageNotExist'))
const PageMaintenance = React.lazy(() => import('../views/pages/error/PageMaintenance'))

export const loginRoutes = [
  { path: '/login', element: Login, name: 'login' },
  { path: '/register', element: Register, name: 'Register' },
  { path: '/forgot-password', element: ForgotPassword, name: 'ForgotPassword' },
  { path: '/reset-password', element: ResetPassword, name: 'ResetPassword' },
  {
    path: '/reset-id/send-mail/:token',
    name: i18n.t('sideBar.sendMail'),
    element: SendMail,
  },
  {
    path: '/reset-password/send-mail/:token',
    name: i18n.t('sideBar.sendMail'),
    element: SendMail,
  },
  {
    path: '/url-expired',
    name: 'url-expired',
    element: UrlExpired,
  },
  {
    path: '/404',
    name: 'url-expired',
    element: PageNotExist,
  },
  {
    path: '/maintenance',
    name: 'url-expired',
    element: PageMaintenance,
  },
  // { path: '/usage-guide', name: 'FAQ', element: UsageGuide },
  // {
  //   path: '/first-time-register',
  //   name: i18n.t('footer.first_time_registration_guide'),
  //   element: FirstTimeRegisterGuide,
  // },
  // {
  //   path: '/login-instructions',
  //   name: i18n.t('footer.login_guide'),
  //   element: LoginInstructions,
  // },
  // {
  //   path: '/about-app',
  //   name: i18n.t('auth.login.honeybee_app'),
  //   element: AboutApp,
  // },
  // { path: '/faq', name: 'FAQ', element: FAQ },
  {
    path: '/form-qa',
    name: i18n.t('my_page.application_inquiry'),
    element: FormQA,
  },
  // {
  //   path: '/terms-of-service',
  //   name: i18n.t('footer.term'),
  //   element: TermsOfService,
  // },
  // {
  //   path: '/privacy-policy',
  //   name: i18n.t('footer.policy'),
  //   element: PrivacyPolicy,
  // },
]

export const mainRoutes = [
  {
    path: '/404',
    name: 'url-expired',
    element: PageNotExist,
  },
  {
    path: '/maintenance',
    name: 'url-expired',
    element: PageMaintenance,
  },
  {
    path: '/url-expired',
    name: 'url-expired',
    element: UrlExpired,
  },
  { path: '/top', name: i18n.t('sideBar.top'), element: Top },
  { path: '/top/statement', name: i18n.t('top.billing_statement'), element: Statement },
  { path: '/top/gas-meter', name: i18n.t('top.gas_meter'), element: GasMeter },
  { path: '/top/electric-meter', name: i18n.t('top.electric_meter'), element: ElectricMeter },
  { path: '/energy-saving', name: i18n.t('sideBar.energySaving'), element: EnergySaving },
  {
    path: '/energy-saving/saving-winter',
    name: i18n.t('sideBar.energySaving'),
    element: EnergySavingContent,
  },
  { path: '/notification', name: i18n.t('sideBar.notification'), element: Notification },
  {
    path: '/notification/detail/:id',
    name: i18n.t('sideBar.notification'),
    element: NotificationDetail,
  },
  { path: '/faq', name: 'FAQ', element: FAQ },
  { path: '/usage-guide', name: 'FAQ', element: UsageGuide },
  { path: '/form-qa', name: 'FormQA', element: InquiryForm },
  { path: '/contact-list', name: 'FAQ', element: ContactList },
  {
    path: '/first-time-register',
    name: i18n.t('footer.first_time_registration_guide'),
    element: FirstTimeRegisterGuide,
  },
  {
    path: '/login-instructions',
    name: i18n.t('footer.login_guide'),
    element: LoginInstructions,
  },
  {
    path: '/about-app',
    name: i18n.t('auth.login.honeybee_app'),
    element: AboutApp,
  },
  {
    path: '/terms-of-service',
    name: i18n.t('footer.term'),
    element: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: i18n.t('footer.policy'),
    element: PrivacyPolicy,
  },
  { path: '/my-page', name: i18n.t('sideBar.myPage'), element: MyPage },
  {
    path: '/my-page/security-safety',
    name: i18n.t('sideBar.security_safety'),
    element: SecuritySafety,
  },
  {
    path: '/my-page/inquiry-form',
    name: i18n.t('my_page.application_inquiry'),
    element: InquiryForm,
  },
  {
    path: '/my-page/profile-settings',
    name: i18n.t('sideBar.profile_settings'),
    element: ProfileSettings,
  },
  {
    path: '/my-page/profile-settings/reset-loginId',
    name: i18n.t('sideBar.profile_settings'),
    element: ResetId,
  },
  {
    path: '/my-page/profile-settings/reset-password',
    name: i18n.t('sideBar.profile_settings'),
    element: ChangePassword,
  },
  {
    path: '/my-page/contract-info',
    name: i18n.t('sideBar.confirm_contract'),
    element: ContractInfo,
  },
  {
    path: '/my-page/notification-setting',
    name: i18n.t('sideBar.notification_settings'),
    element: NotificationSetting,
  },
]
