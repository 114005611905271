export const Endpoint = {
  LOGIN: 'user/login',
  FORGOT_PASSWORD: 'user/forgot-password',
  LOGOUT: 'user/logout',
  CHECK_EMAIL_EXIST_TOKEN_RESET_PASSWORD: 'user/check-email-exist',
  RESET_PASS_WORD: 'user/reset-password',
  COMMON_NOTICE_LIST: 'common-notice/list',
  FORM_REQUEST_BY_NOT_LOGIN: 'form-request/submit-form/not-login',
  FORM_REQUEST_BY_LOGIN: 'form-request/submit-form/login',
  GET_LIST_CONTRACT: 'contract/contract-list',
  GET_CONTRACT_DETAIL: 'contract/contract-detail',
  NOTIFICATION_LIST: 'notifications/notification-list',
  GET_CATEGORY_LIST: 'form_category/list',
  NOTIFICATION_DETAIL: 'notifications/:id',
  REGISTER: 'contract/register',
  REGISTER_CONTRACT_INFO: 'contract/register-contract-info',
  CONTRACT_DETAIL: 'contract/contract-detail',
  REGISTER_EMAIL: 'contract/register-email',
  CHECK_EMAIL_REGISTER_TOKEN: 'contract/check-email-register-token',
  INVOICE_GET_BILL_AMOUNT: 'invoice/get-bill-amount',
  FEE_DETAIL: 'contract/contract-fee-detail',
  METER_DETAIL: 'meter/meter-summary/:contract_id',
  GAS_METER_DETAIL: 'meter/gas-meter-detail',
  ELECTRIC_METER_DETAIL: 'meter/electric-meter-detail',
  ENERGY_SAVING_LIST: 'energy-saving/energy-saving-list',
  NOTICE_UNREAD_SUMMARY: 'notifications/summary-unread/notice',
  SETTING_EMAIL: '/user/setting-email',
  CHECK_EMAIL_CODE: '/user/check-email-code',
  SETTING_PASSWORD: '/user/setting-password',
  LIST_SHOP: 'office/list-shop',
  SETTING_NOTIFICATION_EMAIL: '/user/setting-notification-mail',
  GET_SETTING_NOTIFICATION_EMAIL: '/user/get-receive-mail',
  GET_EMERGENCY: '/emergency/list',
  INVOICE_GET_BILL_MONTH_YEAR: 'pdf/view-invoice',
  PDF_GET_BILL: 'pdf/generate',
}
