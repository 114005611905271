import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { CButton } from '@coreui/react'
import { cilHamburgerMenu, cilX } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { LINK_URL } from 'src/utils/constants'
import { Auth } from 'src/helper/Auth'

export function BurgerMenu(props) {
  const { t } = useTranslation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { user, setVisible, visible } = props
  const isLogin = Auth.getToken()

  return (
    <>
      <CIcon
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        icon={isMenuOpen ? cilX : cilHamburgerMenu}
        customClassName="burger-icon"
      />
      {isMenuOpen && (
        <div className="burger-menu-container" onClick={() => setIsMenuOpen(false)}>
          <ul className="burger-list">
            <li className="burger-list-parent">
              <a
                target="_blank"
                href={LINK_URL.HEADER.URL_1}
                rel="noreferrer"
                className="header-text-list"
              >
                {t('footer.usage_guide')}
              </a>
            </li>
            <li className="burger-list-parent">
              <a
                target="_blank"
                href={LINK_URL.HEADER.URL_2}
                rel="noreferrer"
                className="header-text-list"
              >
                {t('footer.faq')}
              </a>
            </li>
            {isLogin && (
              <li className="burger-list-parent">
                <Link to="/form-qa" className="header-text-list">
                  {t('footer.inquiry_form')}
                </Link>
              </li>
            )}
            {user?.result?.access_token !== undefined && (
              <li className="burger-button d-flex justify-content-end">
                <CButton className="btn-orange btn-auth w-100" onClick={() => setVisible(!visible)}>
                  {t('auth.log_out')}
                </CButton>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  )
}

BurgerMenu.propTypes = {
  user: PropTypes.any,
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}
